<template lang="html">
  <div class="Dashboard">
    <div v-if="isAvailable == false">
      <dashboard-top>
        <screen-title :title="this.$store.state.user.team.name"></screen-title>
      </dashboard-top>
      <div class="container-full">
        <div class="container--center">
          <empty-message>
            Player not found!
          </empty-message>
        </div>
      </div>
    </div>

    <div v-if="player">
      <div class="container ml-0">
        <dashboard-top>
          <Breadcrumbs />
          <screen-title :title="player.name" />
        </dashboard-top>
      </div>
      <div class="container ml-0">
        <section-title :title='`New note for ${player.name}`' />
        <Form :player="player" :note="note" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

import Form from '@/components/Forms/NoteForm';

export default {
  name: 'EditNote',
  components: {Form},
  mounted() {
    this.fetchUser(this.$route.params.id);
  },
  methods: {
    fetchUser(id) {
      this.$store.dispatch('players/GET_PLAYER', id);
    }
  },
  computed: {
    ...mapState({
      isAvailable: state => state.players.current == false ? false : true,
      player: state => state.players.current ? state.players.current : null
    }),
    note() {
      return this.player ? this.player.player_notes.filter(item => item.id == this.$route.params.note)[0] : false;
    }
  },
}
</script>

<style lang="css" scoped>
</style>
